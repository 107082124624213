// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V0znf_7wwd_nZzZ1Vtq0{color:var(--color-main) !important}._5r5G7bY4TtY6YIWujbT{display:inline-block;width:202px;margin-left:8px;border-radius:5px;padding:5px 10px;background-color:#229a16;color:#fff;font-size:13px;text-transform:uppercase}@media(max-width: 768px){._5r5G7bY4TtY6YIWujbT{margin-left:0}}@media(max-width: 440px){._5r5G7bY4TtY6YIWujbT{display:block;width:140px !important}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/TokenTable/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,kCAAA,CACF,sBACE,oBAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA,CACA,wBAAA,CACA,UAAA,CACA,cAAA,CACA,wBAAA,CCFA,yBDPF,sBAWI,aAAA,CAAA,CCRD,yBDHH,sBAaI,aAAA,CACA,sBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.thText\n  color: var(--color-main) !important\n.listed\n  display: inline-block\n  width: 202px\n  margin-left: 8px\n  border-radius: 5px\n  padding: 5px 10px\n  background-color: #229a16\n  color: #fff\n  font-size: 13px\n  text-transform: uppercase\n  @include mobile\n    margin-left: 0\n  @include small-mobile\n    display: block\n    width: 140px !important\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thText": "V0znf_7wwd_nZzZ1Vtq0",
	"listed": "_5r5G7bY4TtY6YIWujbT"
};
export default ___CSS_LOADER_EXPORT___;
